import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PostCard from '../components/PostCard';
import Navbar from '../components/Navbar';
import SearchForm from '../components/SearchForm';


function Wascas() {

  const [loading, setLoading] = useState(true);
  const [isSearch, setIsSearch] = useState(false);
  const [term, setTerm] = useState('');
  const [searchTerm, setSearchTerm] = useState({
      search: '',
  })
  const [wascas, setWascas] = useState([])
  const [items, setItems] = useState([])
  const [activePage, setActivePage] = useState(1)

  useEffect(() => {
    const fecthWascas = async () => {
      await axios.get('/wascas/fetchwascas')
      .then((response) => {
        setWascas(response.data.wascas)
        const rows = Array.from({ length: response.data.pages}, (_, i) => i + 1);
        setItems(rows)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
      }) 
    }

    fecthWascas()

    // eslint-disable-next-line
  }, [])

  const { search } = searchTerm;

  const onChange = (e) => {
      setSearchTerm((prevState) => ({
        [e.target.id]: e.target.value,
      }))
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    
    await axios.post('/wascas/search', searchTerm)
    .then((response) => {
      setWascas(response.data.wascas)
      const rows = Array.from({ length: response.data.pages}, (_, i) => i + 1);
      setItems(rows)
    })
    setLoading(false)
    setTerm(search)
    setSearchTerm('')
    setIsSearch(true)
  }

  const onPagination = async(e) => {
    e.preventDefault()
    setLoading(true)
    const pageNumber = e.target.value;
    await axios.post('/wascas/search/page', { page: pageNumber, search: term })
    .then((response) => {
      setWascas(response.data.wascas)
      setActivePage(pageNumber)
      setLoading(false)
    })
  }


  return (
    <div className='bg-gradient-to-bl from-black from-40% via-sky-950 via-60% to-black to-90% min-h-screen'>
    <SearchForm 
      onChange={onChange} 
      loading={loading} 
      onSubmit={onSubmit} 
      search={search}
    />

    { isSearch && 
      <>
        {wascas?.length > 0 ? (
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-y-8 mx-2 md:mx-12 lg:mx-32 xl:mx-48 md:mt-8'>
            
              {wascas.map((wasca) => (
                <PostCard
                  key={wasca._id} 
                  wasca={wasca}
                />
              ))}
           
          </div>
        ) : 
        <div className='text-white flex flex-col justify-center items-center h-screen'>
          <p>No jobs match '{term}' right now.</p>
          <p>Check back soon</p>
        </div>}
      </>
    }

    { !isSearch && 
      <>
      <ul className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-y-8 mx-2 md:mx-12 lg:mx-32 xl:mx-48 md:mt-8">
        {wascas.map((wasca) => (
          <PostCard
            key={wasca._id} 
            wasca={wasca}
          />
        ))}
      </ul>
    </> }
    <div className='flex justify-center mt-16 pb-24' >
      <ul id='pagination' className='flex justify-between px-2 rounded-3xl bottom-24 bg-white'>
        {items.map((page) => (
          page === activePage ? (
            <li onClick={onPagination} key={`active-${page}`} value={page} id={page} className='bg-green-700 px-4 py-1 rounded-full text-white cursor-pointer mx-1'>{page}</li>):
            (<li onClick={onPagination} key={`inactive-${page}`} value={page} id={page} className='px-4 py-1 rounded-full cursor-pointer hover:bg-green-300 mx-1'>{page}</li>)
        ))}
      </ul>
    </div>


<Navbar />
    </div>
  )
}

export default Wascas