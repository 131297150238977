import React, { useRef, useEffect } from 'react'
import Typed from 'typed.js';
import { Link } from 'react-router-dom';
import { FaLongArrowAltRight, FaSearch, FaNewspaper, FaUserCircle, FaArrowRight } from "react-icons/fa";
import { FaArrowDown } from "react-icons/fa6";
import QuestionImage from '../assets/images/question.png'
import { MdOutlineVerifiedUser, MdNotificationsActive } from "react-icons/md";
import { IoServer } from "react-icons/io5";
import { FaPaperPlane } from "react-icons/fa6";
import { SiPlanetscale } from "react-icons/si";
import Logo from '../assets/images/LOGO.png'

function Home() {

  const el = React.useRef(null); 

  React.useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ["'ve Got Your Back", "'ve Got It", "'ve Meaningful Connections"],
      typeSpeed: 100,
      backSpeed: 100,
      loop: true,
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, []);

  const elementsRef = useRef([]);
  const timelineRef = useRef([])

  useEffect(() => {
    const currentElementsRef = elementsRef.current;
    const currentTimelineRef = timelineRef.current;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('anime');
        }
      });
    });
    currentElementsRef.forEach((el) => {
      if (el) {
        observer.observe(el);
      }
    });

    const observertimeline = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('timeline');
        }
      });
    });
    currentTimelineRef.forEach((el) => {
      if (el) {
        observertimeline.observe(el);
      }
    });

    return () => {
      
        currentElementsRef.forEach((el) => {
          if (el) {
            observer.unobserve(el);
          }
        });
      
      
        currentTimelineRef.forEach((el) => {
          if (el) {
            observertimeline.unobserve(el);
          }
        });
     
    };
  }, []);


  return (
    <>
      <section id="index" className="h-screen flex flex-col bg-gradient-to-tr from-black from-40% via-sky-950 via-70% to-black to-90% pb-2">
        <div id="navbar" className="top-0 text-white flex items-center justify-between w-auto mr-8 ">
            <div className="">
              <img src={Logo} alt="" className='w-1/2' />
            </div>
            <div className="text-orange-500 md:hidden">
                <svg className="w-14 h-14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                    <path fillRule="evenodd" d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z" clipRule="evenodd" />
                  </svg>
                  
            </div>
            <div id="nav-items" className="hidden md:flex w-1/2 justify-end ">
                <a href="#stats" className="nav-link hover:text-sky-600 duration-300 mr-1 px-2 py-1 text-white font-semibold">Services</a>
                <a href="/about.html" className="nav-link hover:text-sky-600 duration-30 mx-3 px-3 py-1 text-white font-semibold">About</a>
                <a href="#footer" className="nav-link hover:text-sky-600 text-white rounded-lg px-3 py-1 mr-3 font-semibold">Contact</a>
                <Link to="/wascas" className="nav-link hover:text-sky-600 text-white rounded-lg px-3 py-1 font-semibold">Jobs</Link>
                
            </div>
        </div>
        
        <div className="flex justify-center items-center h-full">
          <div className='text-white w-full lg:w-3/4 flex flex-col items-center '>
            <h1 className='text-white text-4xl md:text-7xl font-bold'>Freelance Job? </h1>
            <h1 className='text-white text-2xl md:text-5xl font-bold hidden md:block'>We<span ref={el} className='auto-type text-orange-500'></span></h1>
            <Link to="/wascas" className="bg-sky-500 px-8 py-2 rounded-full mt-16 flex items-center text-white">
              <span className='mr-2 text-base font-semibold'>Go To Search</span>
              <span><FaLongArrowAltRight /></span>
            </Link>
          </div>
        </div>
        <div className='text-white flex justify-center mb-2'>
          <a href="#overview" className='text-white'><FaArrowDown className='h-8 w-6 animate-bounce opacity-50' /></a>
        </div>
      </section>


      <section id="overview" className="h-auto md:min-h-screen bg-black text-white py-16 md:px-8 flex items-center justify-center">
        <div className='flex flex-col items-center w-full md:w-3/4 xl:w-1/2'>
          <p className='pb-8 text-center text-xl md:text-2xl'>Better to get a job from <span className='text-orange-500'>abc@xyz.com</span> than from the company website (<span className='text-orange-500'>xyz.com</span>) </p>
          <p className='mb-16 text-xl text-white'>Do you know why?</p>
          <img src={QuestionImage} alt="" className='w-full md:w-3/4 lg:w-1/2' />
          
        </div>
        
      </section>



      <div id='about' className="py-16 flex justify-center h-auto bg-gradient-to-b from-black from-10% via-sky-950 via-70% to-black to-90%">
        <div className='flex justify-center w-full md:w-3/4'>
          <div className='text-white border-0 border-r-2 flex flex-col items-center px-2 md:px-8'>
          <h2 className="text-xl md:text-3xl font-bold mb-8">Remote</h2>
            <div className='bg-black p-6 rounded shadow mb-32 w-full'>
              <h3 className="text-base md:text-xl font-bold mb-4">Location-Specific</h3>
              <p className='text-sm md:text-base'>Often requires you to be in a certain city or country.</p>
            </div>
            <div className="p-6 bg-gray-50 rounded shadow text-black mb-32 w-full">
              <h3 className="text-base md:text-xl font-bold mb-4">Fixed Schedule</h3>
              <p className='text-sm md:text-base'>Set hours and virtual meetings.</p>
            </div>
            <div className='bg-orange-700 p-6 rounded shadow mb-32 w-full'>
              <h3 className="text-base md:text-xl font-bold mb-4">Employee Benefits</h3>
              <p className='text-sm md:text-base'>Regular salary and benefits from one employer.</p>
            </div>

          </div>

          <div className='text-white px-2 md:px-8 flex flex-col items-center'>
          <h2 className="text-xl md:text-3xl font-bold mb-8 text-orange-500">Freelance</h2>
            <div className="p-6 bg-gray-50 rounded shadow text-black mb-32 w-full">
              <h3 className="text-base md:text-xl font-bold mb-4">True Flexibility</h3>
              <p className='text-sm md:text-base'>Work from anywhere in the world.</p>
            </div>
            <div className='bg-black p-6 rounded shadow mb-32 w-full'>
              <h3 className="text-base md:text-xl font-bold mb-4">Your Schedule</h3>
              <p className='text-sm md:text-base'>Choose when and how long you work.</p>
            </div>
            <div className="p-6 bg-gray-50 rounded shadow text-black mb-32 w-full">
              <h3 className="text-base md:text-xl font-bold mb-4">Diverse Clients</h3>
              <p className='text-sm md:text-base'>Multiple clients and projects at your own pace.</p>
            </div>
          </div>
        </div>
    </div>

    {/* About Section */}
    <section className="py-16 bg-black h-auto md:min-h-screen flex items-center">
        <div className="container mx-auto text-center">
            <h2 className="text-3xl font-bold mb-8 text-white">Why Freelients?</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <div className="p-6 rounded shadow text-white">
                  <div className='flex justify-center pb-8'>
                    <FaPaperPlane className='w-32 h-32' />
                  </div>
                    
                    <h3 className="text-xl font-bold mb-4">Direct Access to Clients</h3>
                    <p>Get direct links to decision-makers in top companies.</p>
                </div>
                <div className="p-6 bg-gray-50 rounded shadow">
                  <div className='flex justify-center pb-8'>
                    <MdOutlineVerifiedUser className='w-32 h-32' />
                  </div>
                    
                    <h3 className="text-xl font-bold mb-4">Verified Jobs</h3>
                    <p>All job posts are 100% legit and researched before being posted.</p>
                </div>
                <div className="p-6 text-white rounded shadow">
                <div className='flex justify-center pb-8'>
                    <IoServer className='w-32 h-32' />
                  </div>
                    <h3 className="text-xl font-bold mb-4">Scalable Freelance Careers</h3>
                    <p>Tools and templates to help you build a sustainable freelance career.</p>
                </div>
            </div>
        </div>
    </section>


    {/* Features Section */}
    <section id='stats' className="py-2 md:py-16 bg-gradient-to-b from-black from-10% via-sky-950 via-70% to-black to-90% h-auto md:min-h-screen flex items-center">
        <div className="container mx-auto text-center">
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                <div className="p-6 text-sky-500 rounded shadow">
                    <div className='flex justify-center pb-8'>
                      <FaSearch className='w-32 h-32' />
                    </div>
                    <h3 className="text-xl font-bold mb-4">Powerful Search</h3>
                    <p>Find the exact freelance jobs you're looking for with our advanced search feature.</p>
                </div>
                <div className="p-6 text-sky-500 rounded shadow">
                    <div className='flex justify-center pb-8'>
                      <MdNotificationsActive className='w-32 h-32' />
                    </div>
                    <h3 className="text-xl font-bold mb-4">Email Notifications</h3>
                    <p>Get notified whenever new jobs are posted.</p>
                </div>
                <div className="p-6 text-sky-500 rounded shadow">
                    <div className='flex justify-center pb-8'>
                      <FaNewspaper className='w-32 h-32' />
                    </div>
                    <h3 className="text-xl font-bold mb-4">Proposal Templates</h3>
                    <p>Access template ideas for sending proposals to clients.</p>
                </div>
                <div className="p-6 text-sky-500 rounded shadow">
                    <div className='flex justify-center pb-8'>
                      <SiPlanetscale className='w-32 h-32' />
                    </div>
                    <h3 className="text-xl font-bold mb-4">Scalable Opportunities</h3>
                    <p>Build a career that grows with you.</p>
                </div>
            </div>
        </div>
    </section>

    {/* How It Works Section */}

    <section ref={(el) => (timelineRef.current[0] = el)} className="px-auto max-w-full relative py-16 h-auto flex flex-col justify-center bg-gradient-to-bl from-black from-40% via-sky-950 via-60% to-black to-90% z-10 ">
      <div ref={(el) => (elementsRef.current[0] = el)} className="container opacity-0 text-white left-container relative w-1/2 py-4 px-16">
        <div className='bg-white md:-right-5 left-3 md:left-auto absolute w-10 h-10 rounded-full top-8 z-10' />
        <div className="text-box rounded-lg bg-sky-950 ">
          <h2>Jobs</h2>
          <small>Step 1</small>
          <p>Go to jobs page.</p>
          <span className='left-container-arrow'></span>
        </div>
      </div>
      
      <div ref={(el) => (elementsRef.current[1] = el)} className="container opacity-0 text-white right-container relative w-1/2 py-4 px-16">
      <div className='bg-white left-3 md:-left-5 absolute w-10 h-10 rounded-full top-8 z-10 ' />
        <div className="text-box rounded-lg bg-sky-950 ">
        
          <h2>Search Jobs</h2>
          <small>Step 2</small>
          <p>Use our powerful, fast, and easy-to-use search bar to find the perfect freelance job.</p>
          <span className='right-container-arrow'></span>
        </div>
      </div>
      <div ref={(el) => (elementsRef.current[2] = el)} className="container opacity-0 text-white left-container relative w-1/2 py-4 px-16">
      <div className='bg-white absolute w-10 h-10 rounded-full left-3 md:left-auto md:-right-5 top-8 z-10' />
        <div className="text-box rounded-lg bg-sky-950">
        
          <h2>Apply</h2>
          <small>Step 3</small>
          <p>Reach out directly to clients while following our suggested template which is customized for the particular job.</p>
          <span className='left-container-arrow'></span>
        </div>
      </div>
      <div ref={(el) => (elementsRef.current[3] = el)} className="container opacity-0 text-white right-container relative w-1/2 py-4 px-16">
      <div className='bg-white absolute w-10 h-10 rounded-full left-3 md:-left-5 top-8 z-10' />
        <div className="text-box rounded-lg bg-sky-950">
          <h2>Get Hired</h2>
          <small>Step 4</small>
          <p>Secure freelance jobs and build your career.</p>
          <span className='right-container-arrow'></span>
        </div>
      </div>
    </section>

    {/* Testimonials Section */}
    <section className="py-16 h-auto md:min-h-screen md:flex md:items-center bg-gradient-to-r from-black from-20% via-sky-950 via-70% to-black to-90%">
        <div className="container mx-auto text-center">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <div ref={(el) => (elementsRef.current[4] = el)} className="p-6 opacity-0 text-white rounded shadow flex flex-col items-center">
                    <FaUserCircle className='h-16 w-16 mb-8' />
                    <p>"This platform connected me with amazing clients and helped me grow my freelance career!"</p>
                    <h3 className="text-xl font-bold mt-4">~</h3>
                </div>
                <div ref={(el) => (elementsRef.current[5] = el)} className="p-6 opacity-0 text-sky-500 rounded shadow flex flex-col items-center">
                    <FaUserCircle className='h-16 w-16 mb-8' />
                    <p>"The verified jobs and direct client access are game changers for freelancers."</p>
                    <h3 className="text-xl font-bold mt-4">~</h3>
                </div>
                <div ref={(el) => (elementsRef.current[6] = el)} className="p-6 opacity-0 text-white rounded shadow flex flex-col items-center">
                    <FaUserCircle className='h-16 w-16 mb-8' />
                    <p>"I love the proposal templates and the scalability this platform offers."</p>
                    <h3 className="text-xl font-bold mt-4">~</h3>
                </div>
            </div>
        </div>
    </section>

    {/* Security and Trust Section */}
    <div className='h-auto md:min-h-screen bg-black text-white md:flex md:items-center py-4'>
      <div className='md:flex '>
      <section className="py-16 px-2 md:w-1/2">
        <div className="flex flex-col text-center items-center">
            
            <h2 className="text-3xl font-bold mb-8">Your Security, Our Priority</h2>
            <p>We take extensive measures to verify jobs and protect your information, ensuring a safe and trustworthy experience for all users.</p>
            <div className='flex justify-center pt-8'>
              <MdOutlineVerifiedUser className='w-32 h-32 md:animate-bounce' />
            </div>
        </div>
      </section>

        {/* Call to Action Section */}
        <section className="py-16 md:w-1/2">
            <div className="container text-center flex flex-col items-center">
                <h2 className="text-3xl font-bold mb-8">Ready to Get Started?</h2>
                <p className="mb-6">Search for a job and make those meaningful connections.</p>
                <Link to="/signup" className="px-6 py-2 bg-gradient-to-r from-orange-500 to-slate-800 hover:bg-gradient-to-l text-white rounded-full flex items-center justify-center">
                  <span className='mr-2'>Sign Up Today</span>
                  <span><FaArrowRight /></span>
                </Link>
            </div>
        </section>
      </div>

    </div>
    
        {/* Footer */}
        <footer id="footer" className="text-white px-5 pt-14 pb-8 text-sm bg-gradient-to-r from-black from-20% via-sky-950 via-70% to-black to-90%">
          <div className="flex flex-col md:flex-row justify-around mb-5">
            <div className="w-full md:w-1/4 md:flex md:flex-col md:justify-center md:items-center">
                <p className="text-lg mb-3">ABOUT</p>
                <p className="mb-5">Are you looking for a freelance job that pays? Join Us Today!</p>
                
            </div>
            <div className="border-l-2 border-white hidden md:block">
                <p className=""></p>
            </div>
            <div className="mt-10 md:mt-0 md:w-1/4 flex flex-col">
                <p className="text-lg mb-3">Contact Us</p>
                <p className="mb-1">Email: contact@freelients.com</p>
            </div>
        </div>
        <div className="flex justify-center mb-1">
            <p>&copy; Freelients 2023. All rights reserved</p>
        </div>
        <div className="flex justify-center space-x-6 mb-6 text-xs">
            <a href="/about.html" className="hover:text-sky-400 text-sky-500">About</a>
            <a href="/privacy.html" className="hover:text-sky-400 text-sky-500">Privacy Policy</a>
            <a href="/terms.html" className="hover:text-sky-400 text-sky-500">Terms of Service</a>
        </div>

        </footer>
    </>
  )
}

export default Home