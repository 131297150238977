import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Spinner from '../components/Spinner';
import Header from '../components/Header';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Navbar from '../components/Navbar';
import shareIcon from '../assets/svg/shareIcon.svg';
import { FaLongArrowAltRight } from "react-icons/fa";

function ShowPost() {
  const [wasca, setWasca] = useState(null);
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true);
  const [paymentUrl, setPaymentUrl] = useState('');
  const [sharedLinkCopied, setSharedLinkCopied] = useState(false);


  const params = useParams();
  const navigate = useNavigate()

  useEffect(() => {
      const fetchWasca = async() => {
          await axios.get('/logged-in')
          .then((response) => {
            if(response.data.user) {
              setUser(response.data.user)
            } else {
              setUser(null)
            }
          })
        await axios.get(`/wascas/${params.wascaId}`) 
        .then((response) => {
          setWasca(response.data);
        })
        setLoading(false);
      }

      fetchWasca()
  }, [params.wascaId])

  const handlePayment = async () => {
    try {
      const response = await axios.post('/subscriptions/create-payment')
      setPaymentUrl(response.data.redirect_url)
    } catch (error) {
      console.error('Payment error:', error);
    } 
  }


  const onDelete = async (wascaId) => {
    if(window.confirm('Are you sure you want to delete?')) {
      axios.delete(`/wascas/${params.wascaId}`)
      .then((response) => {
        if(!response.data.error){
          toast.success('Successfully deleted listing')
          navigate('/wascas')
        } else {
          toast.error('not allowed to do that!')
        }
      })
      
    }
  }
 

  if(loading) {
      return <Spinner />
  }
  return (
    <>
    <Header />
      <div
        className="shareIconDiv"
        onClick={() => {
            navigator.clipboard.writeText(window.location.href)
            setSharedLinkCopied(true)
            setTimeout(() => {
                setSharedLinkCopied(false)
            }, 2000)
        }}>
        <img src={shareIcon} alt="share icon" />
      </div>
      {sharedLinkCopied && <p className='linkCopied'>Link Copied!</p> }

    <div className='p-3 max-w-2xl mx-auto w-full mt-12 md:-mt-20 pb-20'>
      <div className='text-xs justify-center flex flex-col items-center'>
        <p className='text-6xl mb-3 text-center'>{wasca.title}</p>
        <ul className='flex'>
          {wasca.tags.map((tag) => (
            <li id='tags' className='text-sky-600 font-semibold mx-2 my-4 px-2 py-1 text-xs rounded-full' key={tag}>{tag}</li>
          ))}
          </ul>
      </div>
      
      <hr className='pt-5 pb-12' />
      <div id='list-style'>
      <div
        dangerouslySetInnerHTML={{ __html: wasca && wasca.content }}
      ></div>

      {user && user.isAdmin &&
      <div className='flex justify-around mb-24'>
        <Link to={`/edit-wasca/${params.wascaId}`} className='bg-sky-600 px-4 py-2 rounded-xl mt-8 text-white font-bold w-1/3 text-sm text-center'>Edit</Link>
        <button onClick={onDelete} className='bg-orange-700 px-4 py-2 rounded-xl mt-8 text-white font-bold w-1/3 text-sm'>Delete</button>
      </div>
      }

      <div
        dangerouslySetInnerHTML={{ __html: wasca && wasca.contact }}
        className='text-sm mt-4'
      ></div>

      {user && user.subscribed &&

      <div className=''>
        <div
          dangerouslySetInnerHTML={{ __html: wasca && wasca.notes }}
          className='text-sm mt-16'
        ></div>
        <a href={wasca.contactUrl} target="_blank" rel="noopener noreferrer" className="bg-gradient-to-r from-sky-500 to-sky-950 px-8 py-2 rounded-full mt-4 flex items-center justify-center text-white">
            <span className='mr-2 text-base font-semibold'>Reach out!</span>
            <span><FaLongArrowAltRight /></span>
        </a>
        <a href={wasca.alternativeContact} target="_blank" rel="noopener noreferrer" className="underline underline-offset-1 mt-8 flex items-center justify-center">
            <span className='mr-2 text-xs font-medium'>Click here if the link doesn't work</span>
            <span><FaLongArrowAltRight /></span>
        </a>
      </div>
      }

      {user && !user.subscribed && !paymentUrl &&
        <div className='flex flex-col justify-center mt-8 lg:mt-16 text-sm'>
          <hr className='mb-8' />
          <p>-Subscribe to automatically get access to client details for the jobs you're interested in.</p>
          <p>-Get access to all job posts.</p>
          <p>-Get personalized recommendations on how to approach each client.</p>
          <p>-Get email alerts for new clients and jobs.</p>
          <p onClick={handlePayment} className='w-3/4 md:w-1/2 mt-2 cursor-pointer flex self-center items-center justify-center bg-gradient-to-r from-orange-500 to-gray-900 text-white rounded-full px-4 p-2 font-semibold'>Subscribe<span className='text-xs ml-1'>KES 650/month (M-PESA)</span></p>
        </div>
      }

      {user === null &&
        <div className='flex flex-col items-center justify-center text-sm mt-8 lg:mt-16'>
          <p>Login or sign up to gain full access</p>
          <p>Get email alerts for new clients and jobs</p>
          <Link to='/login' className='bg-gradient-to-r from-orange-500 to-gray-900 text-white rounded-full px-4 p-2 font-semibold'>Login to view contact details</Link>
        </div>
      }

      <div>
        {paymentUrl && (
          <iframe src={paymentUrl} width="600" height="385" title="Payment" className='mt-12'></iframe>
        )}
      </div>

      </div>
    </div>

    <Navbar />
      
      
  
      

    </>
    
  )
}

export default ShowPost